






















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'appImage',
})
export default class AppImage extends Vue {
  @Prop() url!: string;

  @Prop() appCode!: string;

  @Prop({
    default: 'normal',
  }) size?: string;

  isIcon (str: string) {
    if (str.indexOf('h-icon') === 0) {
      return true;
    }
    return false;
  }

  bColor (colorClass) {
    switch (colorClass) {
      case 'color-1': return 'linear-gradient(45deg, #46C99F 0%, #4FDDAB 100%)';
      case 'color-2': return 'linear-gradient(225deg, #00DAE9 0%, #00CBD0 100%)';
      case 'color-3': return 'linear-gradient(225deg, #38B8FF 0%, #1AACFF 100%)';
      case 'color-4': return 'linear-gradient(45deg, #417CF2 0%, #5D98FF 100%)';
      case 'color-5': return 'linear-gradient(45deg, #7765EC 0%, #9E8DFE 100%)';
      case 'color-6': return 'linear-gradient(45deg, #9A5AE8 0%, #B77FF4 100%)';
      case 'color-7': return 'linear-gradient(45deg, #ED5AAA 0%, #F673B7 100%)';
      default: return 'linear-gradient(45deg, #46C99F 0%, #4FDDAB 100%)';
    }
  }

  imgUrl = '';

  created () {
    if (!this.isIcon(this.url)) {
      if (this.url.includes('/')) {
        this.imgUrl = this.addToken(this.url);
      } else {
        this.imgUrl = this.getDownloadUrl(this.appCode, this.url);
      }
    }
  }

  addToken (url) {
    const token = localStorage.getItem('token');
    const ENV_ORIGIN = localStorage.getItem('ENV_ORIGIN') || '';
    return `${ENV_ORIGIN}${url}&token=${token}`;
  }

  getDownloadUrl (app: string, refId: string) {
    const token = localStorage.getItem('token');
    const ENV_ORIGIN = localStorage.getItem('ENV_ORIGIN') || '';
    const url = `${ENV_ORIGIN}/api/webapi/file/${this.appCode}/download?refId=${refId}&token=${token}`;
    return url;
  }
}
